* {
    padding: 0;
    margin: 0;
    
}

body {
    font-family: $quicksand;
}

.valid {
    color: $green;
}

.warning {
    color: $orange !important;
}

.invalid {
    color: $red !important;
}

.toast-invalid {
    background-color: $error-text !important;
    border-radius: 5px !important;
    color: $off-white !important;
    width: 2000px;
   
}


.toast-valid {
    background-color: $success-text !important;
    border-radius: 5px !important;
    color: $off-white !important;
    text-align: center;
    width: 2000px;
}

.disable {
    background-color: #ccc !important;
    box-shadow: none !important;
    opacity: 0.9 !important;
    pointer-events: none;
}