.questions {
    background-color: $white;
    // border-left: 5px solid $blue;
    width: 100%;
    
  //  padding: $sm $md;

    h2 {
        text-align: center;
        font-weight: lighter;
    }
    h5 {
        text-align: center;
    }

    .options-container {
        //display: inline-block;
      //  margin: 1%;
        width: 50%;
        border-radius: $button-radius;
    }

.que div{
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    
}
    .option {
        background-color: $blue;
        border-radius: $button-radius4;
        color: $white;
        cursor: pointer;
        margin: $normal auto;
        padding: $xs;
       // font-size: $fsm;
        font-weight:$bold ;
        transition: 0.3s linear all;
        text-align: center;
        width: 95%;
        font-family:Cursive;
       
    }

    .option:hover {
        background-color: darken($blue, 0%);
    }
}

.button-container {
    display: flex;
    justify-content: center;
    // margin: 0 auto $sm auto;
    //padding: $md;
    
    width: 100%;
}

.button-container button {
    // border-width: 5px;
   // border-color: $ash;
    color: $off-white;
    cursor: pointer;
    font-family: $quicksand;
    margin-right: $lg;
    margin-left: $lg;
   // padding: $xs $sm;
   // font-size: $fsm;
    border-radius: $button-radius4;
    margin-bottom: $xs;
    
}

.button-container button:first-child {
    background-color: darken($orange, 10%);
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:first-child:hover {
    background-color: darken($nxtgreen, 20%);
}

.button-container button:nth-child(2) {
    background-color: $green;
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:nth-child(2):hover {
    background-color: darken($green, 10%);
}

.button-container button:last-child {
    background-color: $red;
    box-shadow: $shadow;
    transition: 0.2s linear all
}

.button-container button:last-child:hover {
    background-color: darken($red, 10%);
}

