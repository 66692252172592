@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
* {
  padding: 0;
  margin: 0; }

body {
  font-family: "Quicksand", sans-serif; }

.valid {
  color: #57b846; }

.warning {
  color: #ffa500 !important; }

.invalid {
  color: #ea4355 !important; }

.toast-invalid {
  background-color: #F44336 !important;
  border-radius: 5px !important;
  color: #f8f8f8 !important;
  width: 2000px; }

.toast-valid {
  background-color: #4caf50 !important;
  border-radius: 5px !important;
  color: #f8f8f8 !important;
  text-align: center;
  width: 2000px; }

.disable {
  background-color: #ccc !important;
  box-shadow: none !important;
  opacity: 0.9 !important;
  pointer-events: none; }

#home {
  background-image: url(/static/media/background.a5a8d21a.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
  height: 100vh;
  width: 100vw; }
  #home section {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 80%;
    width: 35%; }
  #home .cube {
    font-size: 8rem;
    color: #ffa500; }
  #home h1 {
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
    color: #f8f8f8; }
  #home .play-button-container {
    width: 100%; }
  #home a.play-button {
    background-color: #57b846;
    border-radius: 30px;
    color: #fff;
    display: block;
    cursor: pointer;
    padding: 1rem;
    text-align: center;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all; }
  #home a.play-button:hover {
    background-color: #469338;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  #home .auth-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem; }
  #home #login-button {
    background-color: #ea4355; }
  #home #login-button:hover {
    background-color: #e1192f;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  #home #signup-button {
    background-color: #1da1f2; }
  #home #signup-button:hover {
    background-color: #0c85d0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  #home .auth-container .auth-buttons {
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    padding: 1rem 0;
    text-align: center;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    width: 45%; }

@media (max-width: 768px) {
  #home section {
    padding: 0 1rem;
    width: 100%;
    height: 100%; } }

.instructions {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 1.5rem 3rem; }
  .instructions h1 {
    color: #333;
    font-size: 2rem;
    text-align: center; }
  .instructions .lifeline {
    position: relative;
    top: -3px; }
  .instructions .lifeline-icon {
    color: #57b846;
    cursor: pointer; }
  .instructions .browser-default {
    padding-left: 30px;
    margin-top: 10px;
    margin-bottom: 15px; }
  .instructions img {
    width: 80%;
    height: 50%; }

@media (max-width: 768px) {
  .instructions img {
    width: 100% !important;
    height: 150% !important; } }

.questions {
  background-color: #fff;
  width: 100%; }
  .questions h2 {
    text-align: center;
    font-weight: lighter; }
  .questions h5 {
    text-align: center; }
  .questions .options-container {
    width: 50%;
    border-radius: 30px; }
  .questions .que div {
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center; }
  .questions .option {
    background-color: #1da1f2;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    margin: 1rem auto;
    padding: 0.5rem;
    font-weight: 800;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    text-align: center;
    width: 95%;
    font-family: Cursive; }
  .questions .option:hover {
    background-color: #1da1f2; }

.button-container {
  display: flex;
  justify-content: center;
  width: 100%; }

.button-container button {
  color: #f8f8f8;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  margin-right: 3rem;
  margin-left: 3rem;
  border-radius: 50px;
  margin-bottom: 0.5rem; }

.button-container button:first-child {
  background-color: #cc8400;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all; }

.button-container button:first-child:hover {
  background-color: #255827; }

.button-container button:nth-child(2) {
  background-color: #57b846;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all; }

.button-container button:nth-child(2):hover {
  background-color: #469338; }

.button-container button:last-child {
  background-color: #ea4355;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all; }

.button-container button:last-child:hover {
  background-color: #e1192f; }

.quiz-summary {
  height: 100%;
  font-family: "Quicksand", sans-serif; }
  .quiz-summary span.success-icon {
    color: #FDD835;
    font-size: 7rem; }
  .quiz-summary h1 {
    color: #57b846;
    text-align: center;
    font-size: 3rem;
    margin-top: -1.5rem; }
  .quiz-summary h2 {
    color: #1da1f2; }
  .quiz-summary h4 {
    color: #4d4d4d; }
  .quiz-summary .stats {
    background-color: #f8f8f8;
    max-width: 50%;
    padding: 1.5rem 2rem 2rem 2rem; }
  .quiz-summary .stats h4, .quiz-summary .stats h2 {
    text-align: center; }
  .quiz-summary .stats .stat {
    color: #880E4F;
    font-size: 1rem; }
  .quiz-summary section ul {
    margin-top: 1.5rem;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 1.5rem; }
  .quiz-summary section ul li {
    display: inline-block; }
  .quiz-summary section ul li a {
    padding: 1.5rem !important; }
  .quiz-summary section ul li a:hover {
    color: #f8f8f8; }
  .quiz-summary section ul li:first-child {
    border: 1px solid #1da1f2;
    margin-right: 1.5rem;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all; }
  .quiz-summary section ul li:first-child:hover {
    background-color: #1da1f2; }
  .quiz-summary section ul li:last-child {
    border: 1px solid #57b846;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all; }
  .quiz-summary section ul li:last-child:hover {
    background-color: #57b846; }
  .quiz-summary section ul li:last-child a {
    color: #57b846; }
  .quiz-summary section ul li:last-child a:hover {
    color: #f8f8f8; }
  .quiz-summary .no-stats {
    color: #333;
    margin: 7rem auto; }

@media (max-width: 768px) {
  .quiz-summary {
    font-size: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  div.container.stats {
    margin: 0;
    padding: 1.5rem;
    max-width: 100%;
    width: 100%; } }

