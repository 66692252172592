.quiz-summary {
    height: 100%;
    font-family: $quicksand;
   // background-color: $green;
    span.success-icon {
        color: $yellow;
        font-size: $xxlg;
    }

    h1 {
        color: $green;
        text-align: center;
        font-size: $lg;
        margin-top: -$sm;
    }

    h2 {
        color: $blue;
    }

    h4 {
        color: lighten($off-black, 10%);
    }

    .stats {
        background-color: $off-white;
        max-width: 50%;
        padding: $sm $md $md $md;

    }

    .stats h4, .stats h2 {
        text-align: center;
    }

    .stats .stat {
        color:$darkpink;
        font-size: $normal;

    }
    
    section ul {
        margin-top: $sm;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: $sm;
    }

    section ul li {
        display: inline-block;
       
    }

    section ul li a {
        padding: $sm !important;
    }

    section ul li a:hover {
        color: $off-white;
    }

    section ul li:first-child {
        border: 1px solid $blue;
        margin-right: $sm;
        transition: 0.3s linear all;
    }

    section ul li:first-child:hover {
        background-color: $blue;
    }

    section ul li:last-child {
        border: 1px solid $green;
        transition: 0.3s linear all;
    }

    section ul li:last-child:hover {
        background-color: $green;
    }

    section ul li:last-child a {
        color: $green;
    }

    section ul li:last-child a:hover {
        color: $off-white;
    }

    .no-stats {
        color: $off-black;
        margin: $xxlg auto;
    }
}

@media (max-width: $md-breakpoint) {
    .quiz-summary {
        font-size: $sm;
        padding-left: $xs;
        padding-right: $xs;
    }

    div.container.stats {
        margin: 0;
        padding: $sm;
        max-width: 100%;
        width: 100%;
    }
}