.instructions {
    border: 1px solid darken($off-white, 10%);
    border-radius: 5px;
    margin-top: $lg;
    margin-bottom: $lg;
    padding: $sm $lg;

    h1 {
        color: $off-black;
        font-size: $md;
        text-align: center;
    }

    .lifeline {
        position: relative;
        top: -3px;
    }

    .lifeline-icon {
        color: $green;
        cursor: pointer;
    }

    .browser-default {
        padding-left: 30px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    img {
        width: 80%;
        height: 50%;
    }
} 

@media (max-width: $md-breakpoint) {
    .instructions img {
        width: 100% !important;
        height: 150% !important;
    }
}